import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ласкаво просимо до Café Serendipity!
			</title>
			<meta name={"description"} content={"ДЕ КОЖЕН КОВТОК ВИКЛИКАЄ ПОСМІШКУ"} />
			<meta property={"og:title"} content={"Ласкаво просимо до Café Serendipity!"} />
			<meta property={"og:description"} content={"ДЕ КОЖЕН КОВТОК ВИКЛИКАЄ ПОСМІШКУ"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Ласкаво просимо до Café Serendipity!
				</Em>
			</Override>
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1%20%281%29.jpg?v=2024-06-20T18:59:53.404Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Де кожен ковток викликає посмішку
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--lead" text-transform="uppercase">
							Café Serendipity, розташоване в самому центрі міста, стане вашим затишним місцем відпочинку від суєти повсякденного життя.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Наші двері відкриті для любителів кави, випадкових любителів кави та всіх, хто шукає теплу та затишну атмосферу. Якщо ви тут, щоб попрацювати, зустрітися з друзями чи просто насолодитися моментом спокою, у нас є місце саме для вас.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline2"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					<Em>
						Про Café Serendipity
					</Em>
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18:59:53.417Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-2.jpg?v=2024-06-20T18%3A59%3A53.417Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Ми отримуємо боби від найкращих виробників у всьому світі, гарантуючи, що кожна чашка, яку ми подаємо, має насичений смак і етично вироблена.{" "}
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18:59:53.402Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-1.jpg?v=2024-06-20T18%3A59%3A53.402Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					У Café Serendipity ми віримо, що чудова кава починається з чудових зерен. Наша подорож почалася з пристрасті до виняткової кави та бажання створити простір, де кожен почуватиметься як удома.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1504630083234-14187a9df0f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Наші бариста — ремісники, які ретельно створюють кожен напій до досконалості. Від класичного еспресо до оксамитового латте, наше меню пропонує щось на будь-який смак.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 3rem 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Чому обирають нас?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Якісна кава:{" "}
								<br />
							</Strong>
							<br />
							Ми пишаємося нашим вибором високоякісних зерен, обсмажених до досконалості, щоб отримати найкращий смак. Кожна чашка є свідченням нашої відданості якості.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Затишна атмосфера:{" "}
								<br />
							</Strong>
							<br />
							Наше кафе створено, щоб стати вашим домом далеко від дому. Зручні сидіння, тепле освітлення та дружня атмосфера роблять його ідеальним місцем для відпочинку та відпочинку.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Чудовий сервіс:{" "}
								<br />
							</Strong>
							<br />
							Наша команда захоплюється кавою та прагне надавати першокласне обслуговування. Ми любимо знайомитися з нашими клієнтами і робити кожен візит особливим.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Безкоштовний Wi-Fi:{" "}
								<br />
							</Strong>
							<br />
							залишайтеся на зв’язку, насолоджуючись кавою. Незалежно від того, працюєте ви віддалено чи переглядаєте веб-сторінки, наш безкоштовний Wi-Fi гарантує, що ви зможете робити це без перерв.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Зорієнтованість на спільноту:{" "}
								<br />
							</Strong>
							<br />
							Ми віримо в те, що потрібно віддавати користь спільноті. Від проведення місцевих заходів до підтримки місцевих ініціатив, Café Serendipity прагне справляти позитивний вплив.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px" />
				</Box>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Text margin="0px 0px 0px 0px" color="--dark" font="600 48px --fontFamily-sans">
				Основні моменти нашого меню
			</Text>
			<Text
				text-align="left"
				color="--darkL2"
				font="300 22px --fontFamily-sansHelvetica"
				margin="16px 600px 16px 0px"
				lg-margin="16px 0 16px 0px"
			>
				У Café Serendipity ми пропонуємо широкий вибір напоїв і частувань, щоб порадувати ваші смакові рецептори. Особливості нашого меню:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1579992357154-faf4bde95b3d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Класика еспресо
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							насолоджуйтеся міцними смаками наших напоїв на основі еспресо, зокрема капучіно, макіато та американо.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18%3A59%3A53.403Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Спеціальні сорти кави
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							насолоджуйтеся нашими унікальними витворами, як-от латте з морською сіллю і карамеллю або капучіно з лавандовим медом.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1562547256-2c5ee93b60b7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Чаї та настої
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Для тих, хто не п’є каву, ми пропонуємо вибір чудових чаїв і трав’яних настоїв, ідеальних для заспокійливої ​​перерви.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1539252554453-80ab65ce3586?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Випічка та закуски
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							додайте свій напій до однієї з наших свіжоспечених тістечок або легких закусок. У нашому асортименті є круасани, кекси та кустарні сендвічі.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="list" />
			<Override slot="link2" />
			<Override slot="link" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});